package pages.postdetail

import kotlinx.css.*

class PostDetailStyles {
    companion object{



        val location = CSSBuilder().apply {
            marginBottom = 0.px
        }.toString()

        val marginBottom24 = CSSBuilder().apply {
            marginBottom = 24.px
        }.toString()

        val cardContainer = CSSBuilder().apply {
            marginRight = 0.px
            marginLeft = 0.px
            marginBottom = 48.px
            //padding(16.px)
        }.toString()

    }
}