package components

import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onKeyUpFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSpanElement
import org.w3c.dom.events.KeyboardEvent
import pages.login.LoginStyles
import utils.inlineCss

class LoginFormComponent: BaseComponent{
    val errorElementId = "LoginFormErrorMessage"
    val buttonSpinnerId = "LoginFormSpinner"
    var onLoginButtonClicked: (() -> Unit)? = null

    fun showErrorMessage(error: String?){
        document.getElementById(errorElementId)?.apply {
            textContent = error
            (this as? HTMLElement)?.style?.visibility = if(error != null) "visible" else "hidden"
        }
    }

    fun showSpinner(show: Boolean){
        document.getElementById(buttonSpinnerId)?.apply {
            (this as HTMLSpanElement).hidden = !show
        }
    }

    override fun<T> attachTo(consumer: TagConsumer<T>){
        consumer.render(this)
    }
}

private fun<T> TagConsumer<T>.render(config: LoginFormComponent){

    div(classes = "bg-dark col-3") {

        style = LoginStyles.container

        form(classes = "text-light") {
            div(classes = "form-group") {
                label {
                    htmlFor = "emailInput"
                    +"Email / User ID"
                }
                input(classes = "form-control", type = InputType.email) {
                    id = "emailInput"
                }

            }
            div(classes = "form-group") {
                label {
                    htmlFor = "passwordInput"
                    +"Password"
                }
                input(classes = "form-control", type = InputType.password) {
                    id = "passwordInput"
                    onKeyUpFunction = {
                        if((it as? KeyboardEvent)?.keyCode == 13)
                            config.onLoginButtonClicked?.invoke()
                    }
                }
            }
            small(classes = "form-text text-warning"){
                id = config.errorElementId
                style = CSSBuilder().apply {
                    marginBottom = 12.px
                    //color = Color.red
                }.toString()
            }

            renderButton(config)
        }
    }
}

private fun<T> TagConsumer<T>.renderButton(config: LoginFormComponent){
    button(classes = "btn btn-warning", type = ButtonType.button) {
        span(classes = "spinner-border spinner-border-sm"){
            id = config.buttonSpinnerId
            attributes["role"] = "status"
            attributes["aria-hidden"] = "true"
            hidden = true
        }
        inlineCss {
            backgroundColor = Color("#fccd12")
            color = Color.black
        }
        +" Login"
        onClickFunction = {
            config.onLoginButtonClicked?.invoke()
        }
    }
}


