package components

import kotlinx.css.*
import kotlinx.html.TagConsumer
import kotlinx.html.div
import kotlinx.html.img
import utils.inlineCss

class LoggedOutNavComponent : BaseComponent{
    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render()
    }

}


private fun<T> TagConsumer<T>.render(){
    div {
        inlineCss {
            width = 100.pct
            backgroundColor = Color.black
            padding(vertical = 0.4.rem, horizontal = 1.rem)
        }
        img {
            inlineCss {
                display = Display.block
            }
            src = "images/poruwa-logo.png"
            height = "30"
            width = "auto"
        }
    }
}