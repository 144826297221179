package components.postdetail

import components.BaseComponent
import kotlinx.css.*
import kotlinx.html.TagConsumer
import kotlinx.html.id
import kotlinx.html.img
import kotlinx.html.js.onClickFunction
import utils.inlineCss
import utils.printLog

class ImageComponent(val imageId: String, val size: Int, val blobUrl: String) : BaseComponent{

    var onImageClicked: ((String) -> Unit)? = null


    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(this)
    }
}

private fun<T> TagConsumer<T>.render(component: ImageComponent){
    printLog("attach pending image: ${component.imageId}")
    img {
        inlineCss {
            width = component.size.px
            height = component.size.px
            background = Color.lightGrey.value
            objectFit = ObjectFit.contain
            margin(0.5.rem)
            cursor = Cursor.pointer
        }
        id = component.imageId
        src = component.blobUrl

        onClickFunction = {
            component.onImageClicked?.invoke(component.imageId)
        }
    }
}