package pages.dashboard

import components.*
import getLoader
import getRoot
import hide
import kotlinx.browser.document
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.div
import models.PostDto
import models.PostSearchPageDto
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.get
import org.w3c.dom.set
import org.w3c.dom.url.URLSearchParams
import show
import showSystemAlert
import utils.EnvUtils
import utils.PersistenceManager
import utils.inlineCss
import utils.printLog

class DashboardPage : DashboardViewContract {

    companion object {
        const val ELEMENT_ID_TABLE_CONTAINER = "table_container"
        const val ELEMENT_ID_PAGINATION = "pagination_container"
        const val ELEMENT_ID_SPINNER = "content_spinner"

        const val ARG_SCROLL_Y = "arg_dashboard_scrolly"
    }

    enum class DashboardOption(val path: String, val displayName: String, val status: String) {
        InReview("#/dashboard/in-review", "In Review", "IN_REVIEW"),
        PaymentPending("#/dashboard/payment-pending", "Payment Pending", "PAYMENT_PENDING"),
        Live("#/dashboard/live", "Live", "LIVE"),
        Expired("#/dashboard/expired", "Expired", "EXPIRED"),
        Disabled("#/dashboard/disabled", "Disabled", "DISABLED"),
        PendingEdits("#/dashboard/edit-requests", "Edit Requests", ""),

        Invalid("", "Invalid", "NONE")
    }

    private val sidebarSections = arrayOf(
        DashboardSection(name = "Posts", links = arrayOf(
            DashboardOption.InReview,
            DashboardOption.PaymentPending,
            DashboardOption.Live,
            DashboardOption.Expired,
            DashboardOption.Disabled
        )),
        DashboardSection(name = "Manage Posts", arrayOf(
            DashboardOption.PendingEdits
        ))
    )

    private val presenter = DashboardPresenter(
        this,
        DashboardApi(), MainScope(), PersistenceManager()
    )

    private var shouldSaveScroll = false

    //region ---- View ----

    override fun showLoader() {
        //getLoader().show()
        (document.getElementById(ELEMENT_ID_SPINNER) as? HTMLDivElement)?.show()
    }

    override fun hideLoader() {
        //getLoader().hide()
        (document.getElementById(ELEMENT_ID_SPINNER) as? HTMLDivElement)?.hide()
        getRoot().show()
    }

    override fun showMessage(message: String, isError: Boolean) {
        showSystemAlert(message, isError)
    }

    override fun showListings(resultSet: PostSearchPageDto?) {
        printLog("Total items: ${resultSet?.total}")

        renderTable(resultSet?.results ?: emptyArray())

        //Pagination
        PaginationComponent(resultSet?.pageCount ?: 0, getCurrentPage() - 1, getActiveLink().path)
            .attachTo(ELEMENT_ID_PAGINATION)

        printLog("Scroll to : ${getSavedScrollPosition()}")
        window.scrollTo(0.0, getSavedScrollPosition())
        clearScrollPosition()

        shouldSaveScroll = true
    }

    override fun showListings(resultSet: Array<PostDto>) {
        renderTable(resultSet)
    }

    //endregion

    //region ---- Render ----

    fun render() {
        getLoader().hide()

        shouldSaveScroll = false

        window.addEventListener("scroll", callback = {
            saveScrollPosition()
        })

        printLog("Render dashboard. Path: ${window.location.hash}")

        val hash = window.location.hash
        when {
            hash.startsWith(DashboardOption.Live.path) ||
            hash.startsWith(DashboardOption.InReview.path) ||
            hash.startsWith(DashboardOption.Disabled.path) ||
            hash.startsWith(DashboardOption.Expired.path) ||
            hash.startsWith(DashboardOption.PaymentPending.path) -> {}

            hash.startsWith(DashboardOption.PendingEdits.path) -> {}

            else -> {
                printLog("No dashboard option selected. Setting default and returning.")
                window.location.hash = DashboardOption.InReview.path
                return
            }
        }

        val currentPage = getCurrentPage()
        if (currentPage == 0) {
            printLog("No page number selected. Setting page=1 and returning.")
            updateCurrentPage(1)
            return
        } else {
            printLog("page num: $currentPage")
        }

        printLog("Active Link: ${getActiveLink()}")

        getRoot().append {

            NavBarComponent().attachTo(this)

            div(classes = "container-fluid bg-white") {
                div(classes = "row") {

                    SidebarComponent(sidebarSections, getActiveLink()).attachTo(this)

                    this.main(classes = "col-md-9 ml-sm-auto col-lg-10 px-md-4", block = {
                        role = "main"

                        div {
                            id = ELEMENT_ID_SPINNER
                            div(classes = "spinner-border") {
                                inlineCss {
                                    position = Position.absolute
                                    left = 50.pct
                                    marginTop = 250.px
                                }

                            }
                        }

                        h4 {
                            inlineCss {
                                marginBottom = 1.5.rem
                                marginTop = 0.5.rem
                            }
                            +getActiveLink().displayName
                        }

                        div{
                            id = ELEMENT_ID_TABLE_CONTAINER
                        }
                    })


                    if (getActiveLink() == DashboardOption.PendingEdits) {
                        presenter.loadEditRequests()
                    } else {
                        presenter.loadPosts(
                            getActiveLink().status,
                            pageSize = 25,
                            pageNum = currentPage - 1
                        )
                    }
                }
            }
            div(classes = "col-md-9 ml-sm-auto col-lg-10 px-md-4") {
                nav {
                    inlineCss {
                        margin(vertical = 0.5.rem, horizontal = 0.rem)
                    }
                    id = ELEMENT_ID_PAGINATION
                }
            }

        }
    }

    private fun renderTable(data: Array<PostDto>){
        val headers = arrayOf(
            "Post Id", "Name", "Gender",
            if (getActiveLink() == DashboardOption.InReview) "Created" else "Published",
            "Payment Method", "Status", "Images"
        )

        val selectedId = presenter.getSelectedPostId()

        val table = TableComponent(headers, true)
        table.attachTo(ELEMENT_ID_TABLE_CONTAINER)
        data.forEach {
            table.attachRow(it, getActiveLink(), it.id == selectedId)
        }

        presenter.clearSelectedPost()
    }

    //endregion

    private fun getActiveLink(): DashboardOption {
        return DashboardOption.values().firstOrNull { it.path == window.location.hash.split("?").first() }
            ?: DashboardOption.Invalid
    }

    private fun getCurrentPage(): Int {
        val parts = window.location.hash.split("?")

        return if (parts.size == 1) 0
        else {
            printLog("Search params: ${parts[1]}")
            URLSearchParams(parts[1]).get("page")?.toInt() ?: 0
        }
    }

    private fun updateCurrentPage(pageNum: Int) {
        val parts = window.location.hash.split("?")

        val p = if (parts.size == 1) {
            URLSearchParams()
        } else {
            URLSearchParams(parts[1])
        }

        p.set("page", pageNum.toString())
        window.location.hash = parts[0] + "?" + p.toString()
    }

    private fun saveScrollPosition(){
        if(window.location.hash.startsWith("#/dashboard") && shouldSaveScroll) {
            sessionStorage.setItem(ARG_SCROLL_Y, window.scrollY.toString())
        }
    }

    private fun getSavedScrollPosition(): Double{
        return (sessionStorage[ARG_SCROLL_Y] ?: "0").toDouble()
    }

    private fun clearScrollPosition(){
        if(window.location.hash.startsWith("#/dashboard")) {
            sessionStorage.removeItem(ARG_SCROLL_Y)
        }
    }
}