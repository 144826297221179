package models

data class UserAddressCreateDto(
    val name: String,
    val line1: String,
    val line2: String?,
    val city: String,
    val postCode: String?,
    val latitude: Double?,
    val longitude: Double?,
    val countryCode: String
)