package components.postdetail

import components.BaseComponent
import kotlinx.css.*
import kotlinx.css.properties.LineHeight
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import models.PostDto
import models.PostStatus
import pages.postdetail.PostDetailStyles
import utils.EnvUtils
import utils.inlineCss

class PostHeaderComponent(private val post: PostDto) : BaseComponent{

    var onPublishButtonClicked : (() -> Unit)? = null
    var onDisableButtonClicked : (() -> Unit)? = null
    var showPublishButton = false
    var showDisableButton = false
    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(post, this)
    }
}

private fun<T> TagConsumer<T>.render(post: PostDto, component: PostHeaderComponent){
    div {
        inlineCss {
            marginBottom = 4.rem
        }
        if(post.operatorCreated == true) {
            p(classes = "text-light text-primary badge") {
                inlineCss {
                    backgroundColor = Color.darkCyan
                    color = Color.white
                    marginBottom = 1.rem
                    fontSize = 0.7.rem
                }
                +"OPERATOR CREATED"
            }
        }
        p(classes = "h3"){
            inlineCss {
                marginBottom = 0.1.rem
                lineHeight = LineHeight("1.4rem")
            }
            + "${post.personalInfo.fname} ${post.personalInfo.lname}"
        }
        p(classes = "text-dark"){
            inlineCss {
                marginBottom = 0.px
            }
            + post.id
        }
        val status = PostStatus.values().first { it.status == post.status }
        p(classes = "text-light text-primary badge"){
            inlineCss {
                backgroundColor = Color(status.color)
                color = Color.white
                marginBottom = 0.1.rem
                fontSize = 0.7.rem
            }
            +status.status
        }
        p(classes = "text-muted"){
            style = PostDetailStyles.location
            val country = if(post.personalInfo.residentCountry.code == "ZZ") post.personalInfo.residentCountryOther?.name
            else post.personalInfo.residentCountry.name

            + "${post.personalInfo.residentCity}, $country"
        }
        p{
            inlineCss {
                fontSize = 13.px
            }
            val profession = if(post.personalInfo.profession.name.equals("other", true)) post.personalInfo.professionOther
            else post.personalInfo.profession.name
            +"${post.personalInfo.age}, ${post.personalInfo.height} cm, ${profession}"
        }
        p {
            span {
                a(classes = "text-secondary") {
                    i(classes = "fa fa-external-link")
                    val prefix = if(EnvUtils.isDevEnv()) "https://www-dev" else "https://www"
                    href = "${prefix}.poruwa.lk/post?id=${post.id}"
                    target = "#"
                    +" Public Profile"
                }
            }

        }
        if(component.showPublishButton) {
            button(classes = "btn btn-secondary") {
                inlineCss {
                    marginRight = 0.5.rem
                }
                +"Publish Post"
                onClickFunction = {
                    component.onPublishButtonClicked?.invoke()
                }
            }
        }
        if(component.showDisableButton) {
            button(classes = "btn btn-danger") {
                +"Disable Post"
                onClickFunction = {
                    component.onDisableButtonClicked?.invoke()
                }
            }
        }
    }
}