
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.dom.clear
import kotlinx.html.TagConsumer
import kotlinx.html.button
import kotlinx.html.div
import kotlinx.html.dom.append
import kotlinx.html.dom.create
import kotlinx.html.id
import kotlinx.html.js.div
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pages.dashboard.DashboardPage
import pages.login.LoginPage
import pages.postdetail.PostDetailPage
import utils.AuthHandler
import utils.inlineCss
import utils.printLog


fun main() {
    window.onload = {

        printLog("Hash: ${window.location.hash}")

        window.onhashchange = {
            printLog("On hash changed: ${it.newURL}")
            render()
        }

        document.title = "Poruwa Admin"

        render()
        getRoot().append {
            renderAlertModal()
        }
    }

}

private fun render() {
    getRoot().clear()
    //NavBarComponent().render()


    handleHashRouting()
}

private fun handleHashRouting() {

    val hash = window.location.hash

    if (hash == "/" || hash.isEmpty()) {
        // If logged in redirect from login page to dashboard
        if (AuthHandler.isLoggedIn()) {
            window.location.hash = "/dashboard"
            return
        }
    } else {
        // If not logged in, always redirect to login page
        if (!AuthHandler.isLoggedIn()) {
            printLog("Not permitted to access: $hash. Redirecting to /")
            navigateToLogin()
            return
        }
    }

    when {
        hash == "/" || hash.isEmpty() -> {
            LoginPage().render()
        }
        hash.startsWith("#/dashboard") -> {
            DashboardPage().render()
        }
        hash.startsWith("#/post") -> {
            PostDetailPage().render()
        }
    }
}

fun navigateToLogin() {
    window.location.hash = ""
}

fun getRoot() = document.getElementById("root") as HTMLDivElement
fun getLoader() = document.getElementById("loader") as HTMLDivElement

fun HTMLElement.show() {
    this.style.visibility = "visible"
}

fun HTMLElement.hide() {
    this.style.visibility = "hidden"
}


fun showSystemAlert(message: String, isError: Boolean) {

    val alertModalId = "system_alert_modal"
    document.getElementById(alertModalId)?.remove()

    val alert = document.create.div(classes = "modal fade") {
        id = alertModalId
        div(classes = "modal-dialog") {
            div(classes = "modal-content") {
                div(classes = "modal-body") {
                    inlineCss {
                        padding(0.px)
                    }
                    div(classes = "alert ${if(isError)"alert-danger" else "alert-success"} alert-dismissible fade show") {
                        inlineCss {
                            marginBottom = 0.px
                        }
                        attributes["role"] = "alert"
                        +message
                    }
                    button(classes = "btn btn-link") {
                        inlineCss {
                            float = Float.right
                        }
                        attributes["data-dismiss"] = "modal"
                        +"OK"
                    }
                }
            }
        }
    }

    getRoot().append(alert)

    js("\$('#${alertModalId}').modal()")
}

private fun <T> TagConsumer<T>.renderAlertModal() {

}