package components

import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import navigateToLogin
import utils.AuthHandler
import utils.inlineCss

class NavBarComponent: BaseComponent{

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render()
    }

}

private fun <T> TagConsumer<T>.render() {
    nav(classes = "navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow") {
        inlineCss {
            width = 100.pct
        }
        a(classes = "navbar-brand pa-navbar-brand col-md-3 col-lg-2 mr-0 px-3") {
            img {
                src = "images/poruwa-logo.png"
                height = "30"
                width = "auto"
            }
            href = "#"
        }

        navbarSignOutLink()
    }
}

private fun<T> TagConsumer<T>.navbarSignOutLink(){
    ul(classes = "navbar-nav px-3") {
        li(classes = "nav-item text-nowrap") {
            a(classes = "nav-link text-light", href = "/") {
                onClickFunction = {
                    AuthHandler.clearAuthToken()
                    navigateToLogin()
                }
                +"Sign Out"
            }
        }
    }
}
