package pages.dashboard

import models.PendingEditRequest
import models.PostSearchPageDto
import utils.*

class DashboardApi {

    suspend fun getPosts(status: String, pageSize: Int, pageNum: Int): KResult<PostSearchPageDto> {
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        val query = "?status=${status}&pageNum=${pageNum}&pageSize=${pageSize}"
        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/admin/posts/search${query}",
            headers,
            null
        )
    }

    suspend fun getAllEditRequests(): KResult<Array<PendingEditRequest>>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        return KHttp().requestAndParseResult(KHttp.KhttpMethod.GET, "${EnvUtils.getBaseUrl()}/v1/admin/posts/edit_requests", headers, null)
    }
}