package components

import kotlinx.css.*
import kotlinx.html.*
import pages.dashboard.DashboardPage
import utils.inlineCss


data class DashboardSection(val name: String, val links: Array<DashboardPage.DashboardOption>)

class SidebarComponent(private val sections: Array<DashboardSection>, private val activeLink: DashboardPage.DashboardOption) : BaseComponent{

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(sections, activeLink)
    }
}

private fun<T> TagConsumer<T>.render(sections: Array<DashboardSection>, activeLink: DashboardPage.DashboardOption){
    nav(classes = "col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse") {
        id = "sidebarMenu"
        div(classes = "sidebar-sticky pt-3") {

            sections.forEach {section ->
                h6(classes = "sidebar-heading d-flex justify-content-between " +
                        "align-items-center px-3 mt-4 mb-1 text-white-50"){
                    + section.name
                }

                ul(classes = "nav flex-column") {
                    inlineCss {
                        marginLeft = 0.75.rem
                    }
                    section.links.forEach { option ->
                        li(classes = "nav-item") {
                            a(classes = "nav-link ${if(activeLink == option) "pa-sidebar-active-link" else "text-light"}", href = option.path) {
                                + option.displayName
                            }
                        }
                    }
                }
            }


//            ul(classes = "nav flex-column") {
//                inlineCss {
//                    marginLeft = 0.5.rem
//                }
//                links.forEach { option ->
//
//                    li(classes = "nav-item") {
//                        a(classes = "nav-link ${if(activeLink == option) "pa-sidebar-active-link" else "text-light"}", href = option.path) {
//                            + option.displayName
//                        }
//                    }
//                }
//            }
//            h6(classes = "sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"){
//                span{
//                    +"kshdbvjhds"
//                }
//            }
//            ul(classes = "nav flex-column"){
//                li(classes = "nav-item"){
//                    a(classes = "nav-link"){
//                        +"test"
//                    }
//                }
//            }
        }
    }
}