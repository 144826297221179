package pages.dashboard

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import models.PostDto
import utils.KResultSuccess
import utils.PersistenceManager
import utils.printLog

class DashboardPresenter(
    private val view: DashboardViewContract,
    private val api: DashboardApi,
    private val scope: CoroutineScope,
    private val persistence: PersistenceManager
) {

    fun loadPosts(status: String, pageSize: Int, pageNum: Int){

        view.showLoader()
        scope.launch {
            api.getPosts(status = status, pageSize = pageSize, pageNum = pageNum).let {
                view.hideLoader()

                if(it is KResultSuccess){
                    printLog("Got results: ${it.data.total}")
                    view.showListings(it.data)
                }else{
                    view.showListings(null)
                    view.showMessage("Could not load data. Error: ${it.asError().error.message}", true)
                }
            }
        }
    }

    fun getSelectedPostId() = persistence.getSelectedPost()

    fun clearSelectedPost() = persistence.saveSelectedPost(null)

    fun loadEditRequests(){
        view.showLoader()

        scope.launch {
            api.getAllEditRequests().let {
                view.hideLoader()

                if(it is KResultSuccess){
                    val posts: Array<PostDto> = it.data
                        .filter { it.status == "PENDING" }
                        .map { it.post }
                        .toTypedArray()
                    
                    view.showListings(posts)
                }else{
                    view.showListings(emptyArray())
                    view.showMessage("Could not load data. Error: ${it.asError().error.message}", true)
                }
            }
        }
    }
}