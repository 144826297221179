package utils

import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import models.UserProfileDto
import org.w3c.dom.get
import org.w3c.dom.set

class PersistenceManager {

    fun saveUser(user: UserProfileDto){
        localStorage["user"] = JSON.stringify(user)
    }

    fun getUser(): UserProfileDto? = localStorage["user"]?.let { JSON.parse(it) }

    fun saveSelectedPost(postId: String?){
        if(postId == null)
            sessionStorage.removeItem("selected_post")
        else
            sessionStorage.setItem("selected_post", postId)
    }

    fun getSelectedPost(): String?{
        return sessionStorage["selected_post"]
    }

}