package components.modals

import components.BaseComponent
import getRoot
import kotlinx.browser.document
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import utils.printLog

class ModalWith2ButtonsAndTextEdit(val message: String, val positiveButton: String, val negativeButton: String, val actionId: String?, val isDanger: Boolean) : BaseComponent{

    val modalId = "2ButtonModalComponent2"
    val inputId = "modal-input"
    var onPositiveActionClick: ((String?, String?) -> Unit)? = null

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        document.getElementById(modalId)?.remove()
        consumer.render(this)
    }

    fun show(){
        show(null)
    }
    fun show(div: HTMLDivElement?){
        document.getElementById(modalId)?.remove()

        (div ?: getRoot()).append {
            render(this@ModalWith2ButtonsAndTextEdit)
        }

        printLog("show modal: $modalId")
        js("\$('#${modalId}').modal()")
    }
}

private fun<T> TagConsumer<T>.render(component: ModalWith2ButtonsAndTextEdit){
    div(classes = "modal fade"){
        id = component.modalId
        div(classes = "modal-dialog modal-dialog-centered"){
            div(classes = "modal-content"){
                div(classes = "modal-body"){
                    p{
                        + component.message
                    }
                    input(classes = "form-control", type = InputType.text) {
                        id = component.inputId
                    }
                }
                div(classes = "modal-footer"){
                    button(classes = "btn btn-secondary"){
                        attributes["data-dismiss"] = "modal"
                        + component.negativeButton
                    }
                    button(classes = "btn ${if(component.isDanger) "btn-danger" else "btn-primary" }"){
                        attributes["data-dismiss"] = "modal"
                        component.actionId?.let {
                            attributes["data-action-id"] = it
                        }
                        + component.positiveButton

                        onClickFunction = {
                            val actionId = attributes["data-action-id"]
                            val input = document.getElementById(component.inputId) as HTMLInputElement
                            printLog("modal selected action id: $actionId")
                            component.onPositiveActionClick?.invoke(actionId, input.value)
                        }
                    }
                }
            }
        }
    }
}