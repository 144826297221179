package pages.postdetail

import models.FileUploadResponseDto
import models.GenericResponseDto
import models.PostDto
import models.UserAddressCreateDto
import org.w3c.files.File
import org.w3c.xhr.FormData
import utils.*

class PostDetailApi {
    suspend fun getPostDetail(postId: String): KResult<PostDto> {
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/admin/posts/${postId}",
            headers,
            null
        )
    }

    suspend fun getPostImage(postId: String, imageId: String, width: Int, height: Int): KResult<String>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        val query = "?width=${width}&height=${height}"
        return KHttp().getImageBlob(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/images/${imageId}${query}",
            headers,
            null
        )
    }

    suspend fun getHoroscopeImage(postId: String, imageId: String, width: Int, height: Int): KResult<String>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        val query = "?width=${width}&height=${height}"
        return KHttp().getImageBlob(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/horoscope_images/${imageId}${query}",
            headers,
            null
        )
    }

    suspend fun getPendingPostImage(postId: String, imageId: String, width: Int, height: Int): KResult<String>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        val query = "?width=${width}&height=${height}"
        return KHttp().getImageBlob(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/pending_images/${imageId}${query}",
            headers,
            null
        )
    }

    suspend fun uploadFile(file: File): KResult<FileUploadResponseDto>{
        val formData = FormData()
        formData.append("file", file)
        return KHttp().fileUpload(file)
    }

    suspend fun addFileToPost(postId: String, imageId: String): KResult<GenericResponseDto>{
        val body = "imageId=${imageId}"
        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader(),
            "Content-Type" to "application/x-www-form-urlencoded")

        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.POST,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/images",
            headers,
            body
        )
    }

    suspend fun deletePostImage(postId: String, imageId: String): KResult<GenericResponseDto>{

        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader()
        )
        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.DELETE,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/images/${imageId}",
            headers,
            null
        )
    }

    suspend fun deleteHoroscopeImage(postId: String, imageId: String): KResult<GenericResponseDto>{

        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader()
        )
        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.DELETE,
            "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/horoscope_images/${imageId}",
            headers,
            null
        )
    }

    suspend fun approvePendingEditRequest(editId: String, approve: Boolean, message: String?): KResult<GenericResponseDto>{
        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader(),
            "Content-Type" to "application/x-www-form-urlencoded")
        val body = "approve=${approve}&message=${message ?: ""}"
        return KHttp().requestAndParseResult(KHttp.KhttpMethod.POST, "${EnvUtils.getBaseUrl()}/v1/admin/posts/edit_requests/${editId}/approve", headers = headers, body = body)
    }

    suspend fun publishPost(postId: String): KResult<GenericResponseDto>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        return KHttp().requestAndParseResult(KHttp.KhttpMethod.POST, "${EnvUtils.getBaseUrl()}/v1/admin/posts/${postId}/publish", headers = headers, body = null)
    }

    suspend fun disabledPost(postId: String): KResult<GenericResponseDto> {
        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader(),
            "Content-Type" to "application/x-www-form-urlencoded"
        )
        return KHttp().requestAndParseResult(KHttp.KhttpMethod.POST, "${EnvUtils.getBaseUrl()}/v1/account/posts/${postId}/disable", headers = headers, body = null)
    }

    suspend fun updateUserAddress(memberId: String, address: UserAddressCreateDto): KResult<GenericResponseDto>{

        fun getStringOrNull(str: String?): String?{
            if(str.isNullOrEmpty()) return null
            else return str
        }

        val headers: KHeaders = listOf(
            "Authorization" to AuthHandler.getAuthHeader(),
            "Content-Type" to "application/x-www-form-urlencoded")
        var body = "memberId=${memberId}"

        getStringOrNull(address.name)?.let {
            body += "&name=${address.name}"
        }
        getStringOrNull(address.line1)?.let {
            body += "&line1=${address.line1}"
        }
        getStringOrNull(address.line2)?.let {
            body += "&line2=${address.line2}"
        }
        getStringOrNull(address.city)?.let {
            body += "&city=${address.city}"
        }
        getStringOrNull(address.countryCode)?.let {
            body += "&countryCode=${address.countryCode}"
        }
        getStringOrNull(address.postCode)?.let {
            body += "&postCode=${address.postCode}"
        }
        if(address.latitude != null && address.longitude != null) {
            body += "&latitude=${address.latitude}" +
                    "&longitude=${address.longitude}"
        }

        return KHttp().requestAndParseResult(KHttp.KhttpMethod.POST, "${EnvUtils.getBaseUrl()}/v1/admin/user_address", headers = headers, body = body)
    }

    suspend fun deleteAddress(memberId: String): KResult<GenericResponseDto>{
        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        val query = "?memberId=${memberId}"
        val url = "${EnvUtils.getBaseUrl()}/v1/admin/user_address${query}"
        return KHttp().requestAndParseResult(KHttp.KhttpMethod.DELETE, url, headers = headers, body = null)
    }
}