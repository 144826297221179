package components

import kotlinx.browser.document
import kotlinx.html.HTMLTag
import kotlinx.html.TagConsumer
import kotlinx.html.dom.append

interface BaseComponent {

    /**
     * Allows the callsite to attach a component to a TagConsumer like:
     * document.create.div(){
     *    Component().attachTo(this)
     * }
     */
    fun<T> attachTo(consumer: TagConsumer<T>)

    /**
     * Proxy function that allows the callsite to attach this component
     * to an Html Tag like:
     *  div{
     *      Component().attachTo(this)
     *  }
     */
    fun attachTo(tag: HTMLTag){
        attachTo(tag.consumer)
    }

    /**
     * Proxy function to attach this component to an element of the given id
     */
    fun attachTo(id: String){
        document.getElementById(id)!!.append {
            attachTo(this)
        }
    }
}