package pages.postdetail

import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import models.UserAddressCreateDto
import org.w3c.files.File
import utils.KResultError
import utils.KResultSuccess
import utils.PersistenceManager
import utils.printLog

class PostDetailPresenter(
        private val view: PostDetailViewContract,
        private val api: PostDetailApi,
        private val scope: CoroutineScope,
        private val persistence: PersistenceManager
){

    fun loadPostDetail(postId: String){
        view.showLoader()

        persistence.saveSelectedPost(postId)

        scope.launch {
            loadData(postId, false)
        }
    }

    fun uploadFile(postId: String, file: File){
        printLog("Uploading...")
        view.showHideUploadSpinner(true)
        scope.launch {

            val uploadedFileName = api.uploadFile(file).let {
                if(it is KResultSuccess) {
                    return@let it.data.name
                }else{
                    view.showHideUploadSpinner(false)
                    view.showMessage("Generic file upload failed. Error: ${it.asError().error.message}", true)
                    return@launch
                }
            }

            api.addFileToPost(postId, uploadedFileName).let {
                if(it is KResultError){
                    view.showHideUploadSpinner(false)
                    view.showMessage("Add image failed. Error: ${it.asError().error.message}", true)
                    return@launch
                }
            }

            loadData(postId, true)
            view.showHideUploadSpinner(false)
        }
    }

    fun deleteImage(postId: String, imageId: String, isHoroscope: Boolean){
        scope.launch {
            val result = if(isHoroscope){
                api.deleteHoroscopeImage(postId, imageId)
            }else{
                api.deletePostImage(postId, imageId)
            }
            if(result is KResultSuccess){
                view.removeImage(imageId)
            }else{
                view.showMessage("Image delete failed. Error: ${result.asError().error.message}", true)
            }
        }
    }


    fun approveOrRejectEditRequest(editId: String, approve: Boolean, message: String?){
        //view.showLoader()
        scope.launch {
            api.approvePendingEditRequest(editId, approve, message).let {
                view.hideLoader()
                if(it is KResultSuccess)
                    view.refreshPage()
                else{
                    view.showMessage("Could not approve/reject request. Error: ${it.asError().error.message}", true)
                }
            }
        }
    }

    fun publishPost(postId: String){
        view.showLoader()
        scope.launch {
            api.publishPost(postId).let {
                window.location.reload()
            }
        }
    }

    fun disablePost(postId: String){
        view.showLoader()
        scope.launch {
            api.disabledPost(postId).let {
                window.location.reload()
            }
        }
    }

    private suspend fun loadData(postId: String, onlyImages: Boolean){

        val post = api.getPostDetail(postId).let {
            view.hideLoader()

            if(it is KResultSuccess) return@let it.data
            else{
                view.showMessage("Could not load post data. Error: ${it.asError().error.message}", true)
                return
            }
        }

        //Refresh the post detail view if not just loading images
        if(!onlyImages) {
            view.populatePost(post)
        }

        post.images?.forEach {imageId ->
            api.getPostImage(postId, imageId, 200, 200).let {
                if(it is KResultSuccess){
                    view.populateImage(imageId, it.data, isPending = false, isHoroscope = false)
                }else{
                    view.showMessage("Could not load post image. Error: ${it.asError().error.message}", true)
                }
            }
        }

        //Horoscope images
        post.horoscopeInfo?.images?.forEach {imageId ->
            api.getHoroscopeImage(postId, imageId, 200, 200).let {
                if(it is KResultSuccess){
                    view.populateImage(imageId, it.data, isPending = false, isHoroscope = true)
                }else{
                    view.showMessage("Could not load horoscope image. Error: ${it.asError().error.message}", true)
                }
            }
        }

        post.pendingEditRequests?.firstOrNull()?.let { editRequest ->
            editRequest.images?.filter { it.isNotEmpty() }?.forEach { imageId ->
                printLog("Requesting pending image: ${imageId}")
                api.getPendingPostImage(postId, imageId, 200, 200).let {
                    if (it is KResultSuccess) {
                        view.populateImage(imageId, it.data, isPending = true, isHoroscope = false)
                    } else {
                        printLog("Some images have been deleted since they are over 30 days old.")
                        //view.showMessage("Could not load pending post image. Error: ${it.asError().error.message}", true)
                    }
                }
            }

            editRequest.horoscopeImages?.filter { it.isNotEmpty() }?.forEach { imageId ->
                printLog("Requesting pending image: ${imageId}")
                api.getPendingPostImage(postId, imageId, 200, 200).let {
                    if (it is KResultSuccess) {
                        view.populateImage(imageId, it.data, isPending = true, isHoroscope = true)
                    } else {
                        printLog("Some images have been deleted since they are over 30 days old.")
                        //view.showMessage("Could not load pending post image. Error: ${it.asError().error.message}", true)
                    }
                }
            }
        }
    }

    fun loadImageIntoModal(postId: String, imageId: String, isPendingImage: Boolean, isHoroscope: Boolean){
        printLog("load image: $postId, $imageId")
        scope.launch {

            val result = if(isPendingImage){
                api.getPendingPostImage(postId, imageId, 0, 0)
            }else {
                if(isHoroscope)
                    api.getHoroscopeImage(postId, imageId, 0, 0)
                else
                    api.getPostImage(postId, imageId, 0, 0)
            }

            if(result is KResultSuccess){
                view.populateImageModal(result.data)
            }else{
                printLog("Could not load image")
            }
        }
    }

    fun updateAddress(memberId: String, address: UserAddressCreateDto){
        printLog("Updating address")
        scope.launch {
            view.showHideUpdateAddressSpinner(true)
            val result = api.updateUserAddress(memberId, address)

            view.showHideUpdateAddressSpinner(false)

            if(result is KResultSuccess)
                view.refreshPage()
            else
                view.showMessage("Could not update address: ${result.asError().error.message}", true)
        }
    }

    fun deleteAddress(memberId: String){
        scope.launch {
            val result = api.deleteAddress(memberId)

            if(result is KResultSuccess)
                view.refreshPage()
            else
                view.showMessage("Could not delete address: ${result.asError().error.message}", true)
        }
    }
}