package utils

import kotlinx.browser.window
import kotlinx.coroutines.await
import models.FileUploadResponseDto
import org.w3c.dom.url.URL
import org.w3c.fetch.OMIT
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit
import org.w3c.fetch.SAME_ORIGIN
import org.w3c.files.File
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise
import kotlin.js.json


typealias KHeaders = List<Pair<String, String>>

class KHttp{

    enum class KhttpMethod(val m: String){
        POST(m = "POST"),
        GET(m = "GET"),
        DELETE(m = "DELETE")
    }

    suspend fun <T> requestAndParseResult(method: KhttpMethod, url: String, headers: KHeaders? = null, body: dynamic): KResult<T> {

        val response = window.fetch(url, object : RequestInit {
            override var method: String? = method.m
            override var body: dynamic = body
            override var credentials: RequestCredentials? = if(EnvUtils.isDevEnv()) RequestCredentials.OMIT else RequestCredentials.SAME_ORIGIN
            override var headers: dynamic =
                json(*headers?.toTypedArray() ?: emptyArray())
        }).await()

        return if (response.status == 200.toShort()) {
            KResultSuccess(
                response
                .json()
                .await()
                .unsafeCast<T>()
            )
        } else {
            val e = response
                .text()
                .await()

            var error = KError(500, e)
            if(e.startsWith("{\"code")){
                error = JSON.parse<KError>(e)
            }

            console.log("[KHttp Error] ${error.code} : ${error.message}")

            KResultError(error)
        }
    }

    suspend fun getImageBlob(method: KhttpMethod, url: String, headers: KHeaders? = null, body: dynamic = null): KResult<String>{
        val response = window.fetch(url, object : RequestInit {
            override var method: String? = method.m
            override var body: dynamic = body
            override var credentials: RequestCredentials? = if(EnvUtils.isDevEnv()) RequestCredentials.OMIT else RequestCredentials.SAME_ORIGIN
            override var headers: dynamic =
                    json(*headers?.toTypedArray() ?: emptyArray())
        }).await()

        return if (response.status == 200.toShort()) {
            val b = response
                    .blob()
                    .await()
            KResultSuccess(URL.createObjectURL(b))
        } else {
            val e = response
                    .text()
                    .await()

            var error = KError(500, e)
            if(e.startsWith("{\"code")){
                error = JSON.parse<KError>(e)
            }

            console.log("[KHttp Error] ${error.code} : ${error.message}")
            KResultError(error)
        }
    }


    suspend fun fileUpload(file: File): KResult<FileUploadResponseDto>{

        return Promise<KResult<FileUploadResponseDto>>{ resolve, reject ->
            val xml = XMLHttpRequest()
            val fd = FormData()
            fd.append("file", file)


            xml.withCredentials = !EnvUtils.isDevEnv()
            printLog("XHR WithCredentials: ${xml.withCredentials}")

            xml.upload.onprogress = {
                printLog("upload progress: ${it.loaded}")
            }
            xml.onreadystatechange = {
                if(xml.readyState == 4.toShort()){
                    if(xml.status == 200.toShort()){
                        resolve(KResultSuccess(JSON.parse(xml.responseText)))
                    }else{
                        val e = xml.responseText
                        var error = KError(500, e)
                        if(e.startsWith("{\"code")){
                            error = JSON.parse<KError>(e)
                        }
                        resolve(KResultError(error))
                    }
                }
            }
            xml.open("POST", "${EnvUtils.getBaseUrl()}/v1/generic/upload", true)
            xml.setRequestHeader("accept", "application/json")
            xml.send(fd)
        }.await()

    }


}

data class KError(val code: Int, val message: String)

sealed class KResult<T>(){
    fun asError() = this as KResultError
    fun response() = (this as KResultSuccess).data
}
data class KResultSuccess<T>(val data: T): KResult<T>()
data class KResultError<T>(val error: KError): KResult<T>()




