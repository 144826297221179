package components.modals

import components.BaseComponent
import getRoot
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.dom.append
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLImageElement
import utils.inlineCss
import utils.printLog

class ImageModalComponent : BaseComponent{
    val modalId = "ImageModalComponent"
    val imageId = "ImageModal_img"
    val spinnerId = "ImageModal_spinner"

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(this)
    }

    fun setImage(url: String){
        val img = document.getElementById(imageId) as? HTMLImageElement
        img?.src = url

        (document.getElementById(spinnerId) as? HTMLDivElement)?.remove()
    }

    fun show(div: HTMLDivElement?){
        document.getElementById(modalId)?.remove()

        (div ?: getRoot()).append {
            render(this@ImageModalComponent)
        }

        printLog("show modal: $modalId")
        js("\$('#${modalId}').modal()")
    }

}

private fun<T> TagConsumer<T>.render(component: ImageModalComponent){
    div(classes = "modal fade"){
        id = component.modalId
        div(classes = "modal-dialog modal-dialog-centered"){
            div(classes = "modal-content"){
                div(classes = "modal-body"){
                    div(classes = "spinner-border"){
                        id = component.spinnerId
                        span(classes = "sr-only"){

                        }
                    }
                    img {
                        id = component.imageId
                        inlineCss {
                            maxWidth = 100.pct
                            maxHeight = 100.pct
                            objectFit = ObjectFit.contain
                        }
                    }
                }
            }
        }
    }
}