package utils

import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set


class AuthHandler {
    companion object{

        fun saveAuthToken(token: String){
            localStorage["auth_token"] = token
        }

        fun clearAuthToken(){
            localStorage.clear()
        }

        fun getAuthHeader() = "Bearer ${localStorage["auth_token"]}"

        fun isLoggedIn() = localStorage["auth_token"] != null
    }
}