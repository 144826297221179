package pages.login

import models.LoginResponseDto
import models.UserProfileDto
import utils.*

class LoginApi() {

    suspend fun doLogin(username: String, password: String): KResult<LoginResponseDto>{
        val body = "userId=${username}&password=${password}"
        val headers: KHeaders = listOf("Accept" to "application/json", "Content-Type" to "application/x-www-form-urlencoded")

        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.POST,
            "${EnvUtils.getBaseUrl()}/v1/auth/create_session",
            headers,
            body
        )
    }

    suspend fun getUser(): KResult<UserProfileDto>{

        val headers: KHeaders = listOf("Authorization" to AuthHandler.getAuthHeader())
        return KHttp().requestAndParseResult(
            KHttp.KhttpMethod.GET,
            "${EnvUtils.getBaseUrl()}/v1/account",
            headers,
            null
        )
    }
}