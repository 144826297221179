package components.postdetail

import components.BaseComponent
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import models.UserAddressCreateDto
import models.UserAddressDto
import org.w3c.dom.HTMLInputElement
import pages.postdetail.PostDetailPage
import pages.postdetail.PostDetailStyles
import utils.inlineCss
import utils.printLog

class PostalAddressComponentEditable(private val address: UserAddressDto?, private val onclickAction: (UserAddressCreateDto) -> Unit, private val deleteAction: () -> Unit): BaseComponent{
    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(address, onclickAction, deleteAction)
    }
}

private fun<T> TagConsumer<T>.render(data: UserAddressDto?, onclickAction: (UserAddressCreateDto) -> Unit, deleteAction: () -> Unit){
    div(classes = "table-responsive table-borderless"){
        style = PostDetailStyles.marginBottom24
        table(classes = "table table-sm table-striped"){
            tbody {

                printLog("create address fields")
                createField("address_name", "Name", data?.name ?: "", true)
                createField("address_line_1", "Line 1", data?.line1 ?: "", true)
                createField("address_line_2", "Line 2", data?.line2 ?: "", true)
                createField("address_city", "City", data?.city ?: "", true)
                createField("address_admin_edited", "Admin Edited", data?.adminEdited.toString(), false)
            }
        }
        button(classes = "btn btn-secondary") {
            onClickFunction = {
                //js("document.getElementById(\"${ELEMENT_HIDDEN_INPUT}\").click()")
                val address = UserAddressCreateDto(
                    name = (document.getElementById("address_name") as? HTMLInputElement)?.value ?: "",
                    line1 = (document.getElementById("address_line_1") as? HTMLInputElement)?.value ?: "",
                    line2 = (document.getElementById("address_line_2") as? HTMLInputElement)?.value,
                    city = (document.getElementById("address_city") as? HTMLInputElement)?.value ?: "",
                    postCode = null,
                    latitude = data?.latitude,
                    longitude = data?.longitude,
                    countryCode = data?.country?.code ?: "LK"
                )
                //printLog("Name: ${(document.getElementById("address_name") as? HTMLInputElement)?.value}")
                onclickAction(address)
            }
            inlineCss {
                marginRight = 1.rem
            }
            +"Update Address"
        }
        button(classes = "btn btn-danger") {
            onClickFunction = {
                deleteAction()
            }
            +"Delete Address"
        }
    }
}

private fun<T> TagConsumer<T>.createField(identifier: String, key: String, v: String, editable: Boolean): T{
    return tr(classes = "d-flex"){
        td(classes = "col-3 text-secondary"){
            inlineCss {
                textAlign = TextAlign.right
                paddingRight = 16.px
                fontSize = 13.px
                display = Display.tableCell
                position = Position.relative
                verticalAlign = VerticalAlign.bottom
            }
            +key
        }
        if(editable) {
            td {
                input(classes = "form-control", type = kotlinx.html.InputType.text) {
                    value = v ?: ""
                    id = identifier
                }
            }
        }else{
            td(classes = "col-9"){ + (v ?: "") }
        }
    }
}