package components

import kotlinx.css.margin
import kotlinx.css.px
import kotlinx.html.TagConsumer
import kotlinx.html.a
import kotlinx.html.li
import kotlinx.html.ul
import utils.inlineCss

class PaginationComponent(private val numPages: Int, private val currentPage: Int, private val hrefPath: String) : BaseComponent{
    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(numPages, currentPage, hrefPath)
    }
}

private fun<T> TagConsumer<T>.render(numPages: Int, currentPage: Int, path: String) {

    ul(classes = "pagination justify-content-end flex-wrap") {
        for(i in 0 until numPages) {
            li(classes = "page-item ${if(currentPage == i) "active" else ""}") {
                a(classes = "page-link") {
                    +"${i + 1}"
                    href = "${path}?page=${i+1}"
                }
            }
        }
    }
}