package pages.login

import components.LoggedOutNavComponent
import components.LoginFormComponent
import components.SidebarComponent
import getLoader
import getRoot
import hide
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.css.*
import kotlinx.html.dom.append
import kotlinx.html.js.h2
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import utils.PersistenceManager
import utils.inlineCss
import utils.printLog


private lateinit var loader: HTMLDivElement
private lateinit var root: HTMLDivElement

class LoginPage : LoginViewContract {

    private val presenter = LoginPresenter(this, LoginApi(), MainScope(), PersistenceManager())

    private val loginComponent = LoginFormComponent()

    //region ---- View ----
    override fun showLoader() {
        loginComponent.showSpinner(true)
    }

    override fun hideLoader() {
        loginComponent.showSpinner(false)
    }

    override fun gotoDashboard() {
        window.location.hash = "/dashboard"
    }

    override fun showError(message: String?, visible: Boolean) {
        loginComponent.showErrorMessage(message)
    }
    //endregion

    //region ---- Render ----

    fun render() {
        loader = getLoader()
        root = getRoot()

        loader.hide()

        loginComponent.onLoginButtonClicked = {
            doLogin()
        }

        root.append {

            LoggedOutNavComponent().attachTo(this)

            h2(classes = "card-title text-dark"){
                inlineCss {
                    textAlign = TextAlign.center
                    marginTop = 3.rem
                    marginBottom = 0.5.rem
                }
                + "Admin Login"
            }
            loginComponent.attachTo(this)
        }

        hideLoader()
    }

    //endregion

    private fun doLogin(){
        val emailInput = document.getElementById("emailInput") as HTMLInputElement
        val passwordInput = document.getElementById("passwordInput") as HTMLInputElement
        printLog("Logging in : ${emailInput.value}")
        presenter.onLoginButtonClicked(emailInput.value, passwordInput.value)
    }
}

