package components

import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.dom.create
import kotlinx.html.js.tr
import models.PostDto
import pages.dashboard.DashboardPage
import utils.inlineCss

class TableComponent(val headers: Array<String>, val darkHeader: Boolean): BaseComponent {

    val elementIdTBody = "tableComponentBody"

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.renderTableSkeleton(this)
    }

    fun attachRow(post: PostDto, activeLink: DashboardPage.DashboardOption, isSelected: Boolean){
        val row = document.create.tr {
            if(isSelected) {
                inlineCss {
                    backgroundColor = Color("#e0e0e0")
                }
            }

            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-primary text-medium", href = "#/post?id=${post.id}") {
                    inlineCss {
                        fontWeight = FontWeight.w500
                    }
                    +post.id
                }
            }
            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}") {
                    +"${post.personalInfo.fname} ${post.personalInfo.lname}"
                }

            }
            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}") {

                    +post.personalInfo.gender.substring(0, 1)
                }
            }
            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}") {
                    +if (activeLink == DashboardPage.DashboardOption.InReview) post.createdAt else post.publishedAt ?: "-"
                }
            }
            td(classes = "pa-cell-td"){
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}"){
                    +if (post.payLater) "Bank Tx" else "Card"
                }
            }
            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}") {
                    inlineCss {
                        fontWeight = FontWeight.lighter
                        verticalAlign = VerticalAlign.middle
                    }
                    +post.status
                }
            }
            td(classes = "pa-cell-td") {
                a(classes = "pa-clickable-cell nav-link text-dark", href = "#/post?id=${post.id}") {
                    +(post.images?.size ?: 0).toString()
                }
            }
        }


        document.getElementById(elementIdTBody)?.append(row)
    }
}

private fun<T> TagConsumer<T>.renderTableSkeleton(component: TableComponent){
    div(classes = "table-responsive") {
        table(classes = "table table-sm table-hover") {
            thead(classes = if(component.darkHeader)"bg-dark text-light" else "") {
                tr {
                    component.headers.forEach {
                        th { + it }
                    }
                }
            }
            tbody {
                id = component.elementIdTBody
            }
        }
    }
}
