package components.postdetail

import components.BaseComponent
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import utils.inlineCss

class ImageCardComponent(val imageId: String, val blobUrl: String) : BaseComponent {

    var onDeleteButtonClicked: ((String) -> Unit)? = null
    var onImageClicked: ((String) -> Unit)? = null

    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(this)
    }
}

private fun <T> TagConsumer<T>.render(component: ImageCardComponent) {
    div(classes = "card col-3") {
        id = component.imageId
        inlineCss {
            margin(0.5.rem)
            padding(0.px)
        }
        img(classes = "card-img-top") {
            inlineCss {
                height = 160.px
                background = Color.lightGrey.value
                objectFit = ObjectFit.contain
                cursor = Cursor.pointer
            }
            src = component.blobUrl
            onClickFunction = {
                component.onImageClicked?.invoke(component.imageId)
            }
        }
        div(classes = "card-body text-center") {
            a(classes = "btn btn-secondary"){
                inlineCss {
                    marginRight = 4.px
                }
                i(classes = "fa fa-cloud-download")
            }
            a(classes = "btn btn-danger"){
                inlineCss {
                    marginLeft = 4.px
                }
                i(classes = "fa fa-trash")
                onClickFunction = {
                    component.onDeleteButtonClicked?.invoke(component.imageId)
                }
            }

        }
    }
}