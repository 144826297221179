package components.postdetail

import components.BaseComponent
import kotlinx.css.*
import kotlinx.html.*
import pages.postdetail.PostDetailStyles
import utils.inlineCss

class TwoColumnDataComponent(private val data: Array<Pair<String, String?>>): BaseComponent {
    override fun <T> attachTo(consumer: TagConsumer<T>) {
        consumer.render(data)
    }
}

private fun<T> TagConsumer<T>.render(data: Array<Pair<String, String?>>){
    div(classes = "table-responsive table-borderless"){
        style = PostDetailStyles.marginBottom24
        table(classes = "table table-sm table-striped"){
            tbody {
                data.forEach { (k, v) ->
                    tr(classes = "d-flex"){
                        td(classes = "col-3 text-secondary"){
                            inlineCss {
                                textAlign = TextAlign.right
                                paddingRight = 16.px
                                fontSize = 13.px
                                display = Display.tableCell
                                position = Position.relative
                                verticalAlign = VerticalAlign.bottom
                            }
                            +k
                        }
                        td(classes = "col-9"){ + (v ?: "") }
                    }
                }
            }
        }
    }
}