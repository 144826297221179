package pages.login

import kotlinx.css.*

class LoginStyles {
    companion object{
        var container = CSSBuilder().apply {
            padding(16.px)
            //border(2.px, BorderStyle.solid, Color.slateGrey)
            borderRadius = 6.px
            margin(LinearDimension.auto)
            width = 50.pct
            maxWidth = 450.px
            minWidth = 200.px
        }.toString()
    }
}