package pages.login

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import utils.AuthHandler
import utils.KResultSuccess
import utils.PersistenceManager
import utils.printLog

class LoginPresenter(
    private val view: LoginViewContract,
    private val api: LoginApi,
    private val scope: CoroutineScope,
    private val persistence: PersistenceManager) {


    fun onLoginButtonClicked(username: String, password: String) {

        view.showError(null, false)

        if(username.isEmpty() || password.isEmpty()){
            view.showError("Username and password cannot be empty.", true)
            return
        }else{

        view.showLoader()


        scope.launch {
            val loginResponse = api.doLogin(username, password)
            if(loginResponse is KResultSuccess){
                printLog("Logged in as: ${loginResponse.data.memberId}")
                AuthHandler.saveAuthToken(loginResponse.data.token)

                api.getUser().let {
                    if(it is KResultSuccess) {
                        printLog("Got user: ${it.data.name}")
                        persistence.saveUser(it.data)
                        view.gotoDashboard()
                    }else{
                        AuthHandler.saveAuthToken("")
                        view.hideLoader()
                    }
                }
            }else{
                printLog("Invalid credentials")
                view.hideLoader()
                view.showError("Invalid credentials", true)
            }
        }
            }
    }
}