package pages.postdetail

import components.NavBarComponent
import components.modals.ImageModalComponent
import components.modals.ModalWith2ButtonsAndTextEdit
import components.modals.ModalWith2ButtonsComponent
import components.postdetail.*
import getLoader
import getRoot
import hide
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.css.*
import kotlinx.dom.clear
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import models.EditRequestDto
import models.PostDto
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams
import org.w3c.files.get
import show
import showSystemAlert
import utils.PersistenceManager
import utils.inlineCss
import utils.printLog
import kotlin.js.Date

class PostDetailPage : PostDetailViewContract {

    companion object {
        const val ELEMENT_ID_MAIN_CONTAINER = "id_main_container"
        const val ELEMENT_ID_POST_CONTAINER = "id_post_container"
        const val ELEMENT_IMAGE_CONTAINER = "id_image_container"
        const val ELEMENT_HOROSCOPE_IMAGE_CONTAINER = "id_himage_container"
        const val ELEMENT_IMAGE_EDIT_CONTAINER = "id_image_edit_container"
        const val ELEMENT_HOROSCOPE_IMAGE_EDIT_CONTAINER = "id_himage_edit_container"
        const val ELEMENT_HIDDEN_INPUT = "id_hidden_input"
        const val ELEMENT_ID_UPLOAD_LOADING = "id_image_upload_loading"
        const val ELEMENT_ID_SPINNER = "id_spinner"
    }

    private val presenter = PostDetailPresenter(this, PostDetailApi(), MainScope(), PersistenceManager())
    private var currentPost: PostDto? = null
    private var currentEditRequest: EditRequestDto? = null
    private var imageModal: ImageModalComponent? = null

    override fun showLoader() {
        (document.getElementById(ELEMENT_ID_SPINNER) as? HTMLDivElement)?.show()
    }

    override fun hideLoader() {
        (document.getElementById(ELEMENT_ID_SPINNER) as? HTMLDivElement)?.hide()
    }

    override fun showHideUploadSpinner(show: Boolean) {
        val spinner = document.getElementById(ELEMENT_ID_UPLOAD_LOADING) as? HTMLSpanElement
        spinner?.hidden = !show
    }

    override fun populatePost(post: PostDto) {
        currentPost = post

        //remove fake height to add actual content
        (document.getElementById(ELEMENT_ID_POST_CONTAINER) as HTMLDivElement).style.removeProperty("height")

        renderPostDetails(post)
    }

    override fun showHideUpdateAddressSpinner(show: Boolean) {
        printLog("show hide address spinner: ${show}")
    }

    private fun renderPostDetails(post: PostDto) {

        val postContainer = document.getElementById(ELEMENT_ID_POST_CONTAINER)
        val mainContainer = document.getElementById(ELEMENT_ID_MAIN_CONTAINER)

        postContainer?.apply {
            clear()
            append {

                // < Back button
                p {
                    a(classes = "text-secondary") {
                        href = "javascript:history.back()"
                        +"< Back"
                    }
                }

                // Name, location and age as a card
                PostHeaderComponent(post).apply {
                    showPublishButton = post.status != "DISABLED"
                    showDisableButton = post.status != "DISABLED"
                    attachTo(this@append)
                    onPublishButtonClicked = {
                        ModalWith2ButtonsComponent(
                            message = "Are you sure you want to publish this post?",
                            positiveButton = "Publish",
                            negativeButton = "Cancel",
                            actionId = null,
                            isDanger = false
                        ).also {
                            it.onPositiveActionClick = {
                                printLog("on publish post: ${getPostIdFromUrl()}")
                                presenter.publishPost(getPostIdFromUrl()!!)
                            }
                            it.show()
                        }
                    }
                    onDisableButtonClicked = {
                        ModalWith2ButtonsComponent(
                            message = "Are you sure you want to disable this post?",
                            positiveButton = "Disable",
                            negativeButton = "Cancel",
                            actionId = null,
                            isDanger = false
                        ).also {
                            it.onPositiveActionClick = {
                                printLog("on disable post: ${getPostIdFromUrl()}")
                                presenter.disablePost(getPostIdFromUrl()!!)
                            }
                            it.show()
                        }
                    }
                }

                h5 { +"Personal Info" }
                TwoColumnDataComponent(createBasicInfo(post)).attachTo(this)

                h5 { +"Residency Info" }
                TwoColumnDataComponent(createResidencyInfo(post)).attachTo(this)

                h5 { +"Profession and Habits" }
                TwoColumnDataComponent(createProfessionInfo(post)).attachTo(this)

                h5 { +"Parents' Info" }
                TwoColumnDataComponent(createParentInfo(post)).attachTo(this)

                h5 { +"Private Info" }
                TwoColumnDataComponent(createPrivateInfo(post)).attachTo(this)

                h5 { + "Postal Address" }
                PostalAddressComponentEditable(
                    post.postalAddress,
                    { updatedAddress ->
                        presenter.updateAddress(post.memberId!!, updatedAddress)
                    },
                    {
                        val modal = ModalWith2ButtonsComponent(
                            message = "Are you sure you want to delete this Address?",
                            positiveButton = "Delete",
                            negativeButton = "Cancel",
                            actionId = id,
                            isDanger = true
                        )
                        modal.onPositiveActionClick = { actionId ->
                            presenter.deleteAddress(post.memberId!!)
                        }
                        modal.show(getRoot())
                    }
                ).attachTo(this)
                div(classes = "pa-row") {
                    inlineCss {
                        marginBottom = 2.5.rem
                    }
                }

                if(post.paymentHistory?.isNotEmpty() == true) {
                    h5 { +"Payment Info" }
                    TwoColumnDataComponent(createPaymentInfo(post)).attachTo(this)
                }

                if(post.images?.isNotEmpty() == true){
                    // Photos Section
                    h5 { +"Photos" }
                    div(classes = "pa-row") {
                        id = ELEMENT_IMAGE_CONTAINER
                    }
                }

                renderImageUploadButton()

                if(post.horoscopeInfo?.images?.isNotEmpty() == true){
                    // Horoscope Photos Section
                    h5 {
                        inlineCss { marginTop = 2.5.rem }
                        +"Horoscope Images"
                    }
                    div(classes = "pa-row") {
                        id = ELEMENT_HOROSCOPE_IMAGE_CONTAINER
                    }
                }
            }
        }

        // Pending Edits Section
        if(post.pendingEditRequests?.isNotEmpty() == true){
            mainContainer?.append {
                div(classes = "post-detail-container"){
                    h5{ + "Pending Edits" }
                    createPendingEditSection(post)
                }
            }
        }
    }

    override fun refreshPage() {
        document.location?.reload()
    }

    override fun removeImage(imageId: String) {
        document.getElementById(imageId)?.remove()
    }

    override fun showMessage(message: String, isError: Boolean) {
        showSystemAlert(message, isError)
    }

    override fun populateImageModal(blobUrl: String) {
        imageModal?.setImage(blobUrl)
    }

    override fun populateImage(imageId: String, blobUrl: String, isPending: Boolean, isHoroscope: Boolean) {

        val image = document.getElementById(imageId) as? HTMLElement
        if (isPending) printLog("Image of id '${imageId}': ${image}")
        if (image == null) {
            printLog("Adding image to list: $imageId")

            if (isPending) {
                val imc = ImageComponent(imageId, 160, blobUrl)
                imc.attachTo(if(isHoroscope) ELEMENT_HOROSCOPE_IMAGE_EDIT_CONTAINER else ELEMENT_IMAGE_EDIT_CONTAINER)

                imc.onImageClicked = { id ->
                    imageModal = ImageModalComponent()
                    imageModal?.show(getRoot())
                    presenter.loadImageIntoModal(getPostIdFromUrl()!!, id, isPending, false)
                }
            } else {
                val c = ImageCardComponent(imageId, blobUrl)
                c.attachTo(if(isHoroscope) ELEMENT_HOROSCOPE_IMAGE_CONTAINER else ELEMENT_IMAGE_CONTAINER)
                c.onDeleteButtonClicked = { id ->
                    val modal = ModalWith2ButtonsComponent(
                        message = "Are you sure you want to delete this image? This action cannot be undone.",
                        positiveButton = "Delete",
                        negativeButton = "Cancel",
                        actionId = id,
                        isDanger = true
                    )
                    modal.onPositiveActionClick = { actionId ->
                        presenter.deleteImage(getPostIdFromUrl()!!, actionId!!, isHoroscope)
                    }
                    modal.show(getRoot())
                }

                c.onImageClicked = { id ->
                    imageModal = ImageModalComponent()
                    imageModal?.show(getRoot())
                    presenter.loadImageIntoModal(getPostIdFromUrl()!!, id, isPending, isHoroscope)
                }
            }
        } else {
            printLog("Image already in list. Ignoring : $imageId")
        }
    }


    fun render() {
        getLoader().hide()
        window.scrollTo(0.0, 0.0)

        getRoot().append {
            NavBarComponent().attachTo(this)

            div{
                id = ELEMENT_ID_MAIN_CONTAINER

                div(classes = "post-detail-container") {
                    id = ELEMENT_ID_POST_CONTAINER
                    inlineCss {
                        height = 800.px
                    }

                    div {
                        id = ELEMENT_ID_SPINNER
                        div(classes = "spinner-border") {
                            inlineCss {
                                position = Position.absolute
                                left = 50.pct
                                marginTop = 100.px
                            }

                        }
                    }
                }
            }
        }

        getPostIdFromUrl()?.let {
            presenter.loadPostDetail(it)
        }
    }

    private fun <T> TagConsumer<T>.renderImageUploadButton() {
        div(classes = "pa-row") {
            inlineCss {
                marginTop = 1.rem
            }
            button(classes = "btn btn-secondary") {
                onClickFunction = {
                    js("document.getElementById(\"${ELEMENT_HIDDEN_INPUT}\").click()")
                }
                span(classes = "spinner-border spinner-border-sm") {
                    id = ELEMENT_ID_UPLOAD_LOADING
                    hidden = true
                    inlineCss {
                        marginRight = 0.375.rem
                    }
                }
                +"Add Image"
            }
            input(classes = "custom-file-input") {
                accept = ".jpg,.jpeg,.png"
                id = ELEMENT_HIDDEN_INPUT
                style = CSSBuilder().apply {
                    display = Display.none
                }.toString()
                type = InputType.file
                onChangeFunction = {
                    val input = document.getElementById(ELEMENT_HIDDEN_INPUT) as HTMLInputElement
                    val file = input.files?.get(0)
                    if (file != null) {
                        printLog("input changed: ${input.value}")
                        presenter.uploadFile(getPostIdFromUrl()!!, file)
                    }
                }
            }
        }
    }




    private fun createParentInfo(post: PostDto): Array<Pair<String, String?>> {
        val size = post.parentInfo?.size ?: 0
        val parents = Array<Array<Pair<String, String?>>>(size) {
            emptyArray()
        }
        post.parentInfo?.forEachIndexed { index, parent ->
            val p = arrayOf(
                parent.type.toLowerCase() to "",
                "Ethnicity" to parent.ethnicity.name,
                "Religion" to parent.religion.name,
                "Caste" to parent.caste,
                "Additional Info" to parent.additionalInfo,
            )
            parents[index] = p
        }

        return parents.flatten().toTypedArray()
    }

    private fun createProfessionInfo(post: PostDto): Array<Pair<String, String?>> {
        val profession = if (post.personalInfo.profession.name.equals("other", true)) post.personalInfo.professionOther
        else post.personalInfo.profession.name

        return arrayOf(
            "Education Level" to post.personalInfo.educationLevel.level,
            "Profession" to profession,
            "Drinking" to post.personalInfo.drinking.toLowerCase(),
            "Smoking" to post.personalInfo.smoking.toLowerCase(),
            "Food Preference" to post.personalInfo.foodPreference.type
        )
    }

    private fun createResidencyInfo(post: PostDto): Array<Pair<String, String?>> {
        return arrayOf(
            "Country of Residence" to post.personalInfo.residentCountry.name,
            "State/District" to post.personalInfo.residentRegion?.regionName,
            "City" to post.personalInfo.residentCity,
            "Visa Type" to post.personalInfo.visaType.visaType
        )
    }

    private fun createBasicInfo(post: PostDto): Array<Pair<String, String?>> {
        return arrayOf(
            "Ad id" to post.memberId,
            "First Name" to post.personalInfo.fname,
            "Last Name" to post.personalInfo.lname,
            "Date of Birth" to post.horoscopeInfo?.dob,
            "Age" to post.personalInfo.age.toString(),
            "Religion" to post.personalInfo.religion.name,
            "Ethnicity" to post.personalInfo.ethnicity.name,
            "Civil Status" to post.personalInfo.civilStatus.status,
            "Height" to "${post.personalInfo.height}cm",
            "Origin Country" to post.personalInfo.originCountry.name,
        )
    }

    private fun createPrivateInfo(post: PostDto): Array<Pair<String, String?>> {
        return arrayOf(
            "Additional Info" to post.personalInfo.additionalInfo,
            "Horoscope Matching" to if (post.horoscopeMatching) "Required" else "Not Required",
            "Horoscope Description" to post.horoscopeInfo?.additionalData,
            "Phone Number" to post.phone,
            "Birth Time" to post.horoscopeInfo?.birthTime,
            "Birth City" to post.horoscopeInfo?.birthCity,
            "Created At" to post.createdAt.toUtcDate(),
            "First Published" to post.firstPublishedAt?.toUtcDate(),
            "Latest Published" to post.publishedAt?.toUtcDate(),
            "Expires At" to post.expiresAt?.toUtcDate(),
            "Free Renew" to if (post.freeRenewDone) "Done" else "Not Done",
            "Photos Added" to (post.images?.size ?: 0).toString()
        )
    }

    private fun createAddressInfo(post: PostDto): Array<Pair<String, String?>> {
        if(post.postalAddress == null) return emptyArray()

        return arrayOf(
            "Name" to post.postalAddress!!.name,
            "Line 1" to post.postalAddress!!.line1,
            "Line 2" to post.postalAddress!!.line2,
            "City" to post.postalAddress!!.city,
            "Latitude" to post.postalAddress!!.latitude?.toString(),
            "Longitude" to post.postalAddress!!.longitude?.toString(),
            "Admin Edited" to post.postalAddress!!.adminEdited.toString()
        )
    }

    private fun createPaymentInfo(post: PostDto): Array<Pair<String, String?>>{
        val size = post.paymentHistory?.size ?: 0
        val payments = Array<Pair<String, String?>>(size) {
            return@Array Pair("", "")
        }

        post.paymentHistory?.sortedByDescending { it.createdAt }?.forEachIndexed { index, paymentInfoDto ->
            payments[index] = paymentInfoDto.createdAt.toUtcDate() to "${paymentInfoDto.amount} ${paymentInfoDto.currency}"
        }

        return payments
    }

    private fun DIV.createPendingEditSection(post: PostDto) {
        post.pendingEditRequests?.firstOrNull()?.let { editRequest ->
            currentEditRequest = editRequest

            val parentEdits = Array<Pair<String, String?>>(editRequest.parentDescriptions?.size ?: 0) {
                Pair("", "")
            }
            editRequest.parentDescriptions?.forEachIndexed { index, e ->
                //printLog("parent: ${it.id}, desc: ${it.description}")
                val parent = post.parentInfo?.firstOrNull { it.id == e.id }
                if(parent != null) {
                    parentEdits[index] = Pair("Description (${parent.type.toLowerCase()})", e.description)
                }
            }

            val editData = arrayOf(
                "Edit Submitted" to editRequest.createdAt,
                "Description" to editRequest.additionalInfo,
                "Horoscope Description" to editRequest.horoscopeDetail,
                "Ethnicity" to "${post.personalInfo.ethnicity.name} \u27a1 ${editRequest.ethnicity?.name ?: "Unchanged"}",
                "Religion" to "${post.personalInfo.religion.name} \u27a1 ${editRequest.religion?.name ?: "Unchanged"}",
                "Date of Birth" to "${post.horoscopeInfo?.dob} \u27a1 ${editRequest.dob ?: "Unchanged"}"
            )

            TwoColumnDataComponent(editData + parentEdits).attachTo(this)

            h5 { + "Images" }
            div(classes = "pa-row"){
                id = ELEMENT_IMAGE_EDIT_CONTAINER
            }

            h5 {
                inlineCss {
                    marginTop = 2.5.rem
                }
                + "Horoscope Images"
            }
            div(classes = "pa-row"){
                id = ELEMENT_HOROSCOPE_IMAGE_EDIT_CONTAINER
            }

            div{
                inlineCss {
                    textAlign = TextAlign.right
                }
                button(classes = "btn btn-primary"){
                    i(classes = "fa fa-check")

                    onClickFunction = {
                        val modal = ModalWith2ButtonsComponent(
                            message = "Are you sure you want to accept this edit request?",
                            positiveButton = "Accept",
                            negativeButton = "Cancel",
                            actionId = null,
                            isDanger = false
                        )
                        modal.onPositiveActionClick = {
                            presenter.approveOrRejectEditRequest(editRequest.id, true, null)
                        }
                        modal.show()
                    }
                }
                button(classes = "btn btn-danger"){
                    inlineCss {
                        marginLeft = 8.px
                    }
                    i(classes = "fa fa-times")
                    onClickFunction = {
                        val modal = ModalWith2ButtonsAndTextEdit(
                            message = "Are you sure you want to reject this edit request?",
                            positiveButton = "Reject",
                            negativeButton = "Cancel",
                            actionId = null,
                            isDanger = true
                        )
                        modal.onPositiveActionClick = { _, message ->
                            presenter.approveOrRejectEditRequest(editRequest.id, false, message)
                        }
                        modal.show()
                    }
                }
            }


        }
    }



    private fun getPostIdFromUrl(): String? {
        val parts = window.location.hash.split("?")

        val p = if (parts.size == 1) {
            URLSearchParams()
        } else {
            URLSearchParams(parts[1])
        }

        return p.get("id")
    }

    private fun String.toUtcDate(): String{
        val str = if(this.endsWith("Z")) this else "${this}Z"
        return Date(Date.parse(str)).toUTCString()
    }
}