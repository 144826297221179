
package utils

import kotlinx.css.CSSBuilder
import kotlinx.html.CommonAttributeGroupFacade
import kotlinx.html.style


fun CommonAttributeGroupFacade.inlineCss(handler: CSSBuilder.() -> Unit){
    val builder = CSSBuilder()
    handler(builder)

    this.style = builder.toString()
}

/*
    Meaning of this is:
    const isOdd = require('is-odd')
    where the package name is 'is-odd'
 */
//@JsModule("is-odd")
//@JsNonModule
//external fun isOdd(a: Number): Boolean
