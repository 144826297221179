package utils

class EnvUtils {
    companion object{
        fun getBaseUrl(): String{
            return if(isDevEnv())
                "https://api-dev.poruwa.lk"
            else
                "https://api.poruwa.lk"
        }

        fun isDevEnv(): Boolean{
            val env = js("getDashboardEnvironment()").unsafeCast<String?>()
            return env == "dev"
        }
    }
}
